import React, { Component } from "react";
import WaveSurfer from "wavesurfer.js";
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/Pause'; import IconButton from '@mui/material/IconButton';
import audioFile from "../../assets/video1.mp3";


import { WaveformContianer, Wave, PlayButton } from "./Waveform.styled";

class Waveform extends Component {
    state = {
        playing: false
    };

    componentDidMount() {
        const track = document.querySelector("#track");

        this.waveform = WaveSurfer.create({
            barWidth: 3,
            barRadius: 3,
            barGap: 2,
            barMinHeight: 1,
            cursorWidth: 1,
            container: "#waveform",
            backend: "WebAudio",
            height: 100,
            progressColor: "rgb(233,234,234)",
            responsive: true,
            waveColor: "rgb(76,78,216)",
            cursorColor: "transparent"
        });

        this.waveform.load(track);
    }

    handlePlay = () => {
        this.setState({ playing: !this.state.playing });
        this.waveform.playPause();
    };

    render() {
        return (
            <WaveformContianer>
                <IconButton onClick={this.handlePlay} style={{ width: "80px", padding: 0 }}>
                    {!this.state.playing ? <PlayArrowRoundedIcon style={{ color: "white" }} size="small" /> : <PauseIcon style={{ color: "white" }} size="small" />}
                </IconButton>
                <Wave id="waveform" />
                <audio id="track" src={this.props.url} />
            </WaveformContianer>
        );
    }
}

export default Waveform;
