import React from 'react';
import {
  Box,
  Typography,
  Button,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  pricingBox: {
    textAlign: 'center',
    border: `1px solid ${theme.palette.common.white}`,
    // '&:hover': {
    //   backgroundColor: theme.palette.grey[100],
    // },
    margin: 20,
    backgroundColor: "rgb(33,36,39)",
    borderRadius: "6px",
    padding: "30px",
    flex: 1
  },
  pricingTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 20,
  },
  pricingSubtitle: {
    fontWeight: 600,
    color: "white",
    fontSize: "16px",
    margin: 20
  },
  pricingPrice: {
    fontSize: '2em',
    fontWeight: 700,
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const PricingComponent = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    // <Box
    //   display="flex"
    //   flexDirection="column"
    //   alignItems="center"
    // >
      <Box
        display="flex"
        style={{marginTop: "40px"}}
        justifyContent="space-around"
        css={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box className={classes.pricingBox}>
          <Typography variant="h4" className={classes.pricingTitle}>
            STARTER
          </Typography>
          <Typography variant="body1" className={classes.pricingSubtitle}>
            Our basic plan for individuals and small teams.
          </Typography>
          <Typography variant="h3" className={classes.pricingPrice}>
          &nbsp;&nbsp;$2/minute&nbsp;&nbsp;
          </Typography>
        </Box>
        <Box className={classes.pricingBox}>
          <Typography variant="h4" className={classes.pricingTitle}>
            BUSINESS
          </Typography>
          <Typography variant="body1" className={classes.pricingSubtitle}>
            Our most popular plan for growing businesses.
          </Typography>
          <Typography variant="h3" className={classes.pricingPrice}>
            $200/month
          </Typography>
        </Box>
        <Box className={classes.pricingBox}>
          <Typography variant="h4" className={classes.pricingTitle}>
            ENTERPRISE
          </Typography>
          <Typography variant="body1" className={classes.pricingSubtitle}>
            Our premium plan for large organizations and businesses.
          </Typography>
          <Typography variant="h3" className={classes.pricingPrice}>
            $500/month
          </Typography>
        </Box>
      {/* </Box> */}
    </Box>
  )
};

export default PricingComponent;