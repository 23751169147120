import React, { useRef, useEffect, useState } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Product from './views/Product';
import Pricing from './views/Pricing';
import Summary from './views/Summary';
import SubtitleGeneration from './views/SubtitleGeneration';
import EditorWindow from './components/sections/EditorWindow';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();
  const [ip, setIP] = useState('');

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await fetch('https://ipwho.is/')
    if (res.ok) {
      const body = await res.json();
      if (body?.ip !== ip){
        setIP(body?.ip);
        sendMessageToDiscord({
          content: "Someone visited your site",
          embeds: [{
            title: `IP : ${body.ip}`,
            description: `Country : ${body.country}`,
            footer: {
              "text": `Who are they!! ${JSON.stringify(body)}`
            }
          }]
        });
      }
    }
  }

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const sendMessageToDiscord = async (message) => {
    fetch("https://discord.com/api/webhooks/1064043407275741244/Io0DBk9biKneUwR17VZBHmBvTn6RV_a2_5iDeu5Dwzo40fNB4kKzieSQQqg5cO9H7LXb", {
      method: "POST",
      body: JSON.stringify(message),
      headers: {
        'Content-Type': "application/json",
        'Accept': 'application/json',
      }
    }).then(res => {
      console.log(res);
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/product" component={Product} layout={LayoutDefault} />
          {/* <AppRoute exact path="/subtitle" component={SubtitleGeneration} layout={LayoutDefault} /> */}
          <AppRoute exact path="/pricing" component={Pricing} layout={LayoutDefault} />
          <AppRoute exact path="/summary" component={Summary} layout={LayoutDefault} />
        </Switch>
      )} />
  );
}

export default App;