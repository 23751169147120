import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, useTheme, Dialog, Typography, IconButton, Tooltip, LinearProgress, Stack, Skeleton } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import moment from "moment";
import { getAnalytics, logEvent } from "firebase/analytics";
import { db, storage } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { saveAs } from "file-saver";
import FFMPEG from "react-ffmpeg";
import ReactPlayer from 'react-player';
import { FileDownloadRounded } from '@mui/icons-material';

const styles = (theme) => ({
  error: {
    color: theme.palette.error.main,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "rgb(33,36,39)",
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? 500
        : 700,
    fontSize: 16,
    backgroundColor: "rgb(33,36,39)",
    color: "rgb(233,234,234)",
  };
}

const languages = [
  'Spanish',
  'Hindi',
  'Mandarin',
  'French',
  'German',
  'Telugu',
  'Portuguese',
  'Japanese',
  'Russian',
  'Arabic',
];

const subtitleLangages = [
  "English",
  "Original"
]

const VideoUpload = (props) => {
  const { classes, subtitle } = props;
  const [file, setFile] = React.useState(null);
  const [fileUrl, setFileUrl] = React.useState(null);
  const [text, setText] = React.useState("");
  const [subtitleFileUrl, setSubtitleFileUrl] = React.useState(null);
  const [showVideo, setShowVideo] = React.useState(false);
  const theme = useTheme();
  const fileInput = React.useRef();
  const [email, setEmail] = React.useState('');
  const [videoLink, setVideoLink] = React.useState('');
  const [filePath, setFilePath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [submitClicked, setClicked] = React.useState(false);
  const [languagesSelected, setLanguagesSelected] = React.useState(subtitle ? ["English"] : ["Spanish"]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLanguagesSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleVideoLinkChange = (e) => {
    setVideoLink(e.target.value);
  };

  const handleSubtitleSubmit = async (e) => {
    e.preventDefault();

    if (email && file) {
      setFileUrl(URL.createObjectURL(file));
      const formData = new FormData();
      formData.append('file', file || "");
      formData.append('languagesSelected', languagesSelected[0]);
      setLoading(true);
      setShowVideo(true);
      const res = await fetch("/subtitle", {
        method: "POST",
        body: formData
      });
      if (res.ok) {
        const blob = await res.blob();
        const tmpFile = new File([blob], file.name + '.vtt');
        setSubtitleFileUrl(URL.createObjectURL(tmpFile));
        const reader = new FileReader();
        reader.onload = () => {
          var txt = reader.result;
          txt = txt.replace(/\n/g, "<br />");
          setText(reader.result);
        }
        reader.readAsText(tmpFile);
        // saveAs(blob, file.name + '.vtt');
      } else {
        console.log("Error while generating subtitles");
      }
      setLoading(false);
      //   // await addDoc(collection(db, "videos"), {
      //   //   email: email,
      //   //   videoLink: videoLink,
      //   //   fullPath: 'subtitle',
      //   //   languages: languagesSelected,
      //   //   time: moment.utc().format()
      //   // });
    } else {
      setOpen(true);
    }
  };

  const onFileUpload = async (e) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'select_content', {
      content_type: 'button',
      content_id: 'uploadFileButton'
    });
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      if (subtitle) {
        setFile(file);
        setVideoLink(file?.name);
        setLoading(false);
      } else {
        const fileRef = ref(storage, file?.name);
        uploadBytes(fileRef, file).then((snapshot) => {
          console.log("File uploaded!!", snapshot.metadata.fullPath);
          setFilePath(snapshot.metadata.fullPath);
          setVideoLink(snapshot.metadata.fullPath);
        }).finally(() => {
          setLoading(false);
        });
      }
    }
    e.target.value = null;
  }


  const saveInfo = async (e) => {
    if (email && videoLink && languagesSelected?.length > 0) {
      const analytics = getAnalytics();
      logEvent(analytics, 'select_content', {
        content_type: 'button',
        content_id: 'submitEmailButton'
      });
      e.preventDefault();
      await addDoc(collection(db, "videos"), {
        email: email,
        videoLink: videoLink,
        fullPath: filePath,
        languages: languagesSelected,
        time: moment.utc().format()
      });
      setClicked(true);
      sendMessageToDiscord({
        content: "New Video Request Received",
        embeds: [{
          title: `Email : ${email}`,
          description: `Video : ${videoLink}`,
          footer: {
            "text": "Remember to reach out!!"
          }
        }]
      });
    } else {
      setOpen(true);
      setClicked(false);
    }

  }

  const sendMessageToDiscord = async (message) => {
    fetch("https://discord.com/api/webhooks/1064030199278207058/CqLPpmApAlJlKHwYhfumR-Qvnee-YsP8da7I1pVuGlKqSjCYLA45Km1m9uUaRT-ba20v", {
      method: "POST",
      body: JSON.stringify(message),
      headers: {
        'Content-Type': "application/json",
        'Accept': 'application/json',
      }
    }).then(res => {
      console.log(res);
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
        backgroundColor: "rgb(33,36,39)",
        borderRadius: "4px",
        padding: "60px",
        width: "100%"
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="outlined-required"
        label="Email"
        fullWidth
        inputProps={{ style: { color: "rgb(233,234,234)", fontSize: "16px", fontWeight: "700" } }}
        InputLabelProps={{ style: { color: "rgb(76,78,216)", fontWeight: "600", fontFamily: "Inter", fontSize: "20px" }, shrink: true }}
        placeholder="Enter email address"
        value={email}
        onChange={handleEmailChange}
      />
      <div style={{ display: "flex" }}>
        <TextField
          id="outlined-required"
          label="Video"
          fullWidth
          inputProps={{ style: { color: "rgb(233,234,234)", fontSize: "16px", fontWeight: "700" } }}
          InputLabelProps={{ style: { color: "rgb(76,78,216)", fontWeight: "600", fontFamily: "Inter", fontSize: "20px" }, shrink: true }}
          placeholder="Enter video link or upload video"
          value={videoLink}
          onChange={handleVideoLinkChange}
        />
        <Button variant='outlined' component='label' style={{ borderColor: "rgb(76,78,216)", background: "rgb(76,78,216)", marginTop: "8px", height: "55px" }}>
          {!loading && <FileUploadRoundedIcon style={{ fontSize: '24px', color: "white" }} />}
          {loading && <CircularProgress size={20} style={{ color: "white" }} />}
          <input hidden accept="video/*" type="file" onChange={onFileUpload} onClick={(event) => { event.target.value = null }} />
        </Button>
      </div>

      <div>
        <FormControl sx={{ m: 1 }}
          fullWidth
        >
          <InputLabel
            id="demo-multiple-name-label"
            style={{ color: "rgb(76,78,216)", fontWeight: "600", fontFamily: "Inter", fontSize: "20px" }}
          >Language
          </InputLabel>
          <Select
            fullWidth
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple={subtitle ? false : true}
            value={languagesSelected}
            style={{ color: "rgb(233,234,234)", fontSize: "16px", fontWeight: "700", textAlign: "left" }}
            onChange={handleChange}
            input={<OutlinedInput label="Languageee" />}
            MenuProps={MenuProps}
          >
            {subtitle ? subtitleLangages.map((lang) => (
              <MenuItem
                key={lang}
                value={lang}
                style={getStyles(lang, languagesSelected, theme)}
              >
                {lang}
              </MenuItem>
            )) : languages.map((lang) => (
              <MenuItem
                key={lang}
                value={lang}
                style={getStyles(lang, languagesSelected, theme)}
              >
                {lang}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {!submitClicked && <Button
        variant="contained"
        style={{
          backgroundColor: "rgb(76,78,216)",
          fontWeight: "600",
          marginTop: "20px",
          fontFamily: "Inter",
          fontSize: "15px",
          textTransform: "none"
        }}
        onClick={(e) => subtitle ? handleSubtitleSubmit(e) : saveInfo(e)}
      >
        {subtitle ? "Generate Subtitles" : "Submit"}
      </Button>}
      {submitClicked && <div style={{
        color: "white",
        fontWeight: "600",
        marginTop: "20px",
        fontFamily: "Inter",
        fontSize: "15px",
        textTransform: "none"
      }}>Thanks, we'll update you soon.</div>}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={5000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="error" style={{ fontSize: "15px" }}>
          Some fields are missing
        </Alert>
      </Snackbar>

      <Dialog open={showVideo} onClose={() => setShowVideo(false)}
        sx={{
          '& .MuiPaper-root': { maxWidth: 'unset', width: "80vw", height: "80vh" }
        }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#151719",
          width: "80vw",
          height: "80vh",
          borderRadius: "4px"
        }}>
          <div style={{ flex: "65%", margin: 10 }}>
            <ReactPlayer
              url={fileUrl}
              controls={true}
              playing={!loading}
              width={"100%"}
              height={"100%"}
              config={{
                file: {
                  attributes: {
                    crossOrigin: "true",
                  },
                  tracks: [
                    {
                      kind: "subtitles",
                      src: subtitleFileUrl,
                      default: true,
                    },
                  ],
                },
              }} />
          </div>
          <div style={{ flex: "35%" }}>
            {loading ?
              <Stack spacing={1} style={{margin: 10}} >
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{backgroundColor: "grey"}}/>
                <Skeleton variant="circular" width={40} height={40} style={{backgroundColor: "grey"}}/>
                <Skeleton variant="rounded"  height={150} style={{backgroundColor: "grey"}}/>
                <Skeleton variant="rounded" height={150} style={{backgroundColor: "grey"}} />
              </Stack> :
              <>
                <Tooltip placement="top" title="Download" arrow>
                  <IconButton
                    style={{ float: "right" }}
                    onClick={() => saveAs(subtitleFileUrl, file.name + '.vtt')}
                  >
                    <FileDownloadRounded style={{ fontSize: 24, color: "white" }} />
                  </IconButton>
                </Tooltip>
                <Typography
                  style={{
                    whiteSpace: "pre-wrap",
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "white",
                    margin: 10
                  }}>
                  {text}
                </Typography>
              </>}
          </div>
        </div>
        {/* <ReactPlayer
          url={fileUrl}
          controls={true}
          playing={true}
          width={"100%"}
          height={"100%"}
          config={{
            file: {
              attributes: {
                crossOrigin: "true",
              },
              tracks: [
                {
                  kind: "subtitles",
                  src: subtitleFileUrl,
                  default: true,
                },
              ],
            },
          }} />
          <Drawer
          variant="permanent"
          anchor='right'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '20%' },
          }}
          open
        >
          <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
          enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
          imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
          Convallis convallis tellus id interdum velit laoreet id donec ultrices.
          Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
          adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
          nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
          leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
          feugiat vivamus at augue. At augue eget arcu dictum varius duis at
          consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
          sapien faucibus et molestie ac.
        </Typography> */}
        {/* </Drawer> */}

        {/* <Button
          variant="contained"
          style={{
            backgroundColor: "rgb(76,78,216)",
            fontWeight: "600",
            fontFamily: "Inter",
            fontSize: "15px",
            textTransform: "none"
          }}
          onClick={() => saveAs(subtitleFileUrl, file.name + '.vtt')}>Download Subtitles</Button> */}
      </Dialog>
    </Box>
  );
};

export default withStyles(styles)(VideoUpload);