import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { saveAs } from "file-saver";
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, useTheme, Dialog, Drawer, Typography, ButtonGroup } from '@mui/material';
import ReactPlayer from 'react-player';


const styles = (theme) => ({
    error: {
        color: theme.palette.error.main,
    },
});

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const EditorWindow = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const { fileUrl, subtitleFileUrl, text } = props;

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div style={{ padding: 50 }}>
                <div className="hero-content" style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: "65%", maxHeight: "40vh", margin: 10 }}>
                        <ReactPlayer
                            url={fileUrl}
                            controls={true}
                            playing={true}
                            width={"100%"}
                            height={"100%"}
                            config={{
                                file: {
                                    attributes: {
                                        crossOrigin: "true",
                                    },
                                    tracks: [
                                        {
                                            kind: "subtitles",
                                            src: subtitleFileUrl,
                                            default: true,
                                        },
                                    ],
                                },
                            }} />
                    </div>
                    <div style={{ flex: "35%" }}>
                        <Typography paragraph>
                            {text}
                        </Typography>
                    </div>
                </div>
            </div>
        </section>
        // <section
        //     {...props}
        //     className={outerClasses}
        // >
        //     <div className="container-sm">
        //         <div className={innerClasses}>
        //             <ReactPlayer
        //                 url={fileUrl}
        //                 controls={true}
        //                 playing={true}
        //                 width={"100%"}
        //                 height={"100%"}
        //                 config={{
        //                     file: {
        //                         attributes: {
        //                             crossOrigin: "true",
        //                         },
        //                         tracks: [
        //                             {
        //                                 kind: "subtitles",
        //                                 src: subtitleFileUrl,
        //                                 default: true,
        //                             },
        //                         ],
        //                     },
        //                 }} />
        //             <Drawer
        //                 variant="permanent"
        //                 anchor='right'
        //                 sx={{
        //                     display: { xs: 'none', sm: 'block' },
        //                     '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '20%' },
        //                 }}
        //                 open
        //             >
        //                 <Typography paragraph>
        //                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        //                     tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
        //                     enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
        //                     imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
        //                     Convallis convallis tellus id interdum velit laoreet id donec ultrices.
        //                     Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
        //                     adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
        //                     nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
        //                     leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
        //                     feugiat vivamus at augue. At augue eget arcu dictum varius duis at
        //                     consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
        //                     sapien faucibus et molestie ac.
        //                 </Typography>
        //             </Drawer>
        //         </div>
        //     </div>
        //     {/* <Button
        // variant="contained"
        // style={{
        //   backgroundColor: "rgb(76,78,216)",
        //   fontWeight: "600",
        //   fontFamily: "Inter",
        //   fontSize: "15px",
        //   textTransform: "none"
        // }}
        // onClick={() => saveAs(subtitleFileUrl, file.name + '.vtt')}>Download Subtitles</Button> */}
        // </section>
    )
}

EditorWindow.propTypes = propTypes;
EditorWindow.defaultProps = defaultProps;

export default EditorWindow;