import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyAhk1rxPuaghvEF9QBo8CB2u96GbJ-3ju4",
    authDomain: "say-ai.firebaseapp.com",
    projectId: "say-ai",
    storageBucket: "say-ai.appspot.com",
    messagingSenderId: "552181958057",
    appId: "1:552181958057:web:3881886ba1402d780530b8",
    measurementId: "G-D1RLYPT3BF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
let analytics;
if (app.name && typeof window !== 'undefined') {
    analytics = getAnalytics(app);
}


export {
    app,
    analytics,
    db,
    storage
}