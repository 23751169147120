import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../utils/SectionProps';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import { Button as MuiButton } from '@mui/material';
import Image from '../components/elements/Image';
import Modal from '../components/elements/Modal';
import VideoUpload from '../components/VideoUpload';
import YouTubeIcon from '../assets/images/youtube_logo.png';
import ChatGptIcon from '../assets/images/chatgpt_logo.png';
import ChromeIcon from '../assets/images/chrome_logo.png';
import { Link } from 'react-router-dom';

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Summary = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    useEffect(() => {
        document.title = "YouTube Summary with ChatGPT";
    }, []);

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" >
                            <div style={{display: "flex", marginLeft: "20%"}}>YouTube Summary <img src={YouTubeIcon} alt="Youtube" style={{marginLeft: "8px"}} width="80px"></img></div>
                            <div style={{display: "flex", marginLeft: "25%", marginTop: "10px"}} className="text-color-primary" >with ChatGPT <img src={ChatGptIcon} alt="ChatGPT" style={{marginLeft: "8px"}} width="60px"></img></div>
                        </h1>
                        <div className="container-xs">
                            <p className="m-0 mb-32 reveal-from-bottom" >
                                Get summary and transcript of YouTube videos. <br />Learn better with ChatGPT
                            </p>
                        </div>
                        <Link to={{ pathname: "https://saysummary.tech" }} target="_blank" style={{borderRadius: "8px", background: "transparent", border: "1px solid white", fontSize: "24px", alignItems: "center", height: "auto"}} className="button button-primary" ><img src={ChromeIcon} alt="Chrome" style={{height: "40px"}}></img>&nbsp;&nbsp;Install on chrome</Link>
                        <div style={{ marginTop: "10px", fontSize: "14px" }} >No sign up required</div>
                    </div>
                </div>
                <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="200">
                    <iframe
                        width="100%"
                        height="600"
                        src="https://www.youtube.com/embed/vmWKt26y1mA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe>
                </div>
            </div>
        </section >
    );
}

Summary.propTypes = propTypes;
Summary.defaultProps = defaultProps;

export default Summary;