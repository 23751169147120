import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useMemo, useEffect, useState, useRef } from "react";
import ReactPlayer from 'react-player';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Player from "react-wavy-audio";
import audioFile from "../../assets/tts.m4a";
import Waveform from './Waveform';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const audio = useMemo(() => new Audio(require("../../assets/video1.mp3")), []);
  const [playing, setPlaying] = useState(false);
  const [pos, setPos] = useState(0);
  const waveformRef = useRef();

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Empowering creators to go multi-lingual',
    // paragraph: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.'
  };

  const onClick = () => {
    toggle();
  }

  const handlePosChange = (e) => {
    setPos(e.originalArgs[0]);
  }

  console.log('Entered');

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Accuracy like never before
                </div>
                <h3 className="mt-0 mb-12">
                  Speech to text
                </h3>
                <p className="m-0">
                Generate subtitles, text from any audio or video with world’s most powerful tool Say AI. Whether you are a content creator, a business, or an individual user, this speech to text technology can help you to make your audio and video content more accessible, useful, and effective.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <ReactPlayer url="https://www.youtube.com/embed/QO5_A0fvi44" width='100%'
                  height='270px'
                  controls={true}
                >
                </ReactPlayer>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Intact text emotions
                </div>
                <h3 className="mt-0 mb-12">
                  Intelligent translation
                </h3>
                <p className="m-0">
                At Say AI, we have developed a cutting-edge translation software that outperforms Google's in every way. Our software uses advanced algorithms and machine learning to provide intelligent,fast, accurate, and reliable translations of text, audio and video content.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <br />
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '40ch' },
                    backgroundColor: "rgb(33,36,39)",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div style={{ width: "100%" }}>
                    <TextField
                      id="outlined-required"
                      label="ENGLISH"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      inputProps={{ style: { color: "rgb(233,234,234)", fontSize: "16px", fontWeight: "700" } }}
                      InputLabelProps={{ style: { color: "rgb(76,78,216)", fontWeight: "600", fontFamily: "Inter", fontSize: "20px" } }}
                      defaultValue="My name is Saydub. It is my business to dub videos what others can't comprehend."
                    />
                    <TextField
                      id="outlined-required"
                      label="HINDI"
                      InputProps={{
                        readOnly: true,
                      }}
                      inputProps={{ style: { color: "rgb(233,234,234)", fontSize: "16px", fontWeight: "700" } }}
                      InputLabelProps={{ style: { color: "rgb(76,78,216)", fontWeight: "600", fontFamily: "Inter", fontSize: "20px" } }}
                      defaultValue="मेरा नाम सेदब है। यह मेरा काम है कि मैं ऐसे वीडियो डब करूं जो दूसरे समझ न सकें।"
                    />
                    <TextField
                      id="outlined-required"
                      label="SPANISH"
                      InputProps={{
                        readOnly: true,
                      }}
                      inputProps={{ style: { color: "rgb(233,234,234)", fontSize: "16px", fontWeight: "700" } }}
                      InputLabelProps={{ style: { color: "rgb(76,78,216)", fontWeight: "600", fontFamily: "Inter", fontSize: "20px" } }}
                      defaultValue="Mi nombre es Saydub. Mi negocio es doblar videos que otros no pueden comprender."
                    />
                    <TextField
                      id="outlined-required"
                      label="FRENCH"
                      InputProps={{
                        readOnly: true,
                      }}
                      inputProps={{ style: { color: "rgb(233,234,234)", fontSize: "16px", fontWeight: "700" } }}
                      InputLabelProps={{ style: { color: "rgb(76,78,216)", fontWeight: "600", fontFamily: "Inter", fontSize: "20px" } }}
                      defaultValue="Je m'appelle Saydub. C'est mon travail de doubler des vidéos que les autres ne peuvent pas comprendre."
                    />
                  </div>
                </Box>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  As original as ever
                </div>
                <h3 className="mt-0 mb-12">
                  Text to Speech
                </h3>
                <p className="m-0">
                Are you tired of using robotic and monotone text-to-speech systems that lack the naturalness and emotions of human speech? Do you want to improve the quality and effectiveness of your audio, video content, and make it more engaging and appealing to your audience? If so, our text-to-speech software is the solution you've been looking for.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                {/* <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} /> */}
                <Box
                  component="form"
                  sx={{
                    backgroundColor: "rgb(33,36,39)",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  {/* <div style={{  width: "100%", textAlign: "center" }}> */}
                  {/* <div style={{ backgroundColor: "rgb(33,36,39)", textAlign: "center", padding: 10, borderRadius: 4 }}>
                    <Player
                      // imageUrl="https://pbs.twimg.com/media/A-lU5FnCcAA1Edi.jpg"
                      audioUrl={audioFile}
                      id="unique-one"
                      // waveStyles={{
                      //   // cursorWidth: 1,
                      //   // container: waveformRef.current,
                      //   progressColor: "rgb(233,234,234)",
                      //   // responsive: true,
                      //   waveColor: "rgb(76,78,216)",
                      //   cursorColor: "transparent",
                      //   // barWidth: 0
                      // }}
                      zoom={0}
                    // waveJson
                    hideImage="true"
                    // hideWave="true"
                    // containerStyle={}
                    />
                  </div> */}
                  {/* <Waveform url={audioFile}/> */}
                  <Waveform url={audioFile}/>
                {/* </div> */}
                </Box>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;